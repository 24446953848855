import { render, staticRenderFns } from "./file-upload-input.vue?vue&type=template&id=0601c244&scoped=true"
import script from "./file-upload-input.vue?vue&type=script&lang=js"
export * from "./file-upload-input.vue?vue&type=script&lang=js"
import style0 from "./file-upload-input.vue?vue&type=style&index=0&id=0601c244&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0601c244",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default,UploadDropZone: require('/codebuild/output/src2285020857/src/web/components/upload-drop-zone.vue').default,PCheckIcon: require('/codebuild/output/src2285020857/src/web/components/p-check-icon.vue').default})
